import React, { useState } from "react";
import { Row, Col, Container, Breadcrumb, Form, Card, Alert } from "react-bootstrap";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { isUserSignedIn } from "../../components/accountManagement";
import { SideBarOptions, accountMenuLinks, TopMenuOptions } from "../../components/genericMenu";
import { updateUserPublicProfile } from "../../actions/user-action";
import { store } from "../../redux-store";
import { callAPI } from "../../components/api";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";

const uri = "/account/profile/";

const MyAccountPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);
  const [bannerMessage, setBannerMessage] = useState("");

  console.log("userData.userName=" + userData.userName);

  const updatePublicProfile = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    let data = {
      display_name: form.displayname.value,
    };
    setBannerMessage("");

    var result = await callAPI("/users/me", "PATCH", data, userData, store, true);
    if (result.status === "success") {
      store.dispatch(updateUserPublicProfile(form.displayname.value));
      setBannerMessage("Saved.");
    } else {
      if (result.apiStatus === 400) {
        if (result.data.detail === "DISPLAY_NAME_IN_USE") {
          setBannerMessage("Display name is in use by another user.");
        } else {
          setBannerMessage(result.data.detail);
        }
      } else {
        console.error("Error:", result.message);
        setBannerMessage("Error: " + result.message);
      }
    }
  };

  return (
    <Layout pageInfo={{ pageName: "account" }} sideBarOptions={SideBarOptions(accountMenuLinks, uri, "My Account")}>
      <Seo title="Public Profile" keywords={[`account`]} description="Account page" url={uri} />
      <Container fluid>
        <div id="top-menu">
          <TopMenuOptions menuLinks={accountMenuLinks} currentLink={uri} menuTitle="My Account" />
        </div>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/">Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Public Profile</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        {isUserSignedIn(userData) ? (
          <Container fluid>
            <Card className="personal-info-card">
              <Card.Body>
                <ModalHeader>
                  <ModalTitle>Public Profile</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  {bannerMessage === "" ? (
                    <></>
                  ) : (
                    <Alert className="ms-2 me-2 mt-2" variant="warning">
                      {bannerMessage}
                    </Alert>
                  )}
                  <Form noValidate onSubmit={updatePublicProfile} id="public-profile-form" autoComplete="off">
                    <div className="mb-5">
                      Choose a public display name that will be shown for your reviews and comments.
                    </div>
                    <Row className="mb-2">
                      <Col sm={4}>
                        <Form.Label htmlFor="inputDisplayname" className="form-label">
                          <b>Public Display Name</b>
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          required
                          className="form-control"
                          id="displayname"
                          defaultValue={userData.userDisplayName}
                        />
                      </Col>
                    </Row>

                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </Form>
                </ModalBody>
              </Card.Body>
            </Card>
          </Container>
        ) : (
          <>Not logged in {navigate("/account/signin/")}</>
        )}
      </Container>
    </Layout>
  );
};

export default MyAccountPage;
